<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div
    v-if="errorMessage"
    class="row py-5 my-5 text-center justify-content-center text-gray-700 fs-2"
  >
    {{ errorMessage }}
  </div>
  <div v-else class="row px-5 text-center justify-content-center">
    <div class="col-6 card shadow">
      <div class="row px-5 text-center justify-content-center">
        <div class="col-12">
          <div class="mt-5 mb-3 bg-secondary py-5 px-4 rounded fw-bold">
            <div class="blueFit fs-6">
              Utilizza quest'area per impostare la tue credenziali di accesso a
              SGAT
            </div>
          </div>
          <div class="p-4 pb-5">
            <div class="pt-2 d-flex justify-content-center">
              <span
                class="fw-bold text-gray-800 align-self-center pe-5"
                style="width: 8rem"
                >Username:</span
              >
              <input class="form-control" v-model="name" disabled />
            </div>
            <div class="pt-2 d-flex">
              <span
                class="fw-bold text-gray-800 align-self-center pe-5"
                style="width: 8rem"
                >Password:</span
              >
              <input
                class="form-control"
                v-model="password"
                type="password"
                placeholder="********"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 text-end pb-5">
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              @click="editSgatCredenziali()"
            >
              Salva modifiche
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,

  //watch,
} from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { ref } from "vue";
import { viewSgat, editSgat } from "@/requests/sgatRequests";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "credenziali-sgat",
  components: { Loading },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Credenziali Sgat", []);
    });

    const route = useRoute();

    const id_societa = ref(route.params.id_societa);
    const name = ref(null);
    const password = ref(null);
    const id_utente = ref(null);

    const errorMessage = ref(null);

    const isLoading = ref(false);

    const viewSgatCredenziali = () => {
      isLoading.value = true;
      viewSgat(id_societa.value).then((res) => {
        isLoading.value = false;

        if (res.status == 200) {
          name.value = res.data.results[0].name;
          //password.value = res.data.results[0].pass_sgat;
          id_utente.value = res.data.results[0].id_utente;
        } else {
          errorMessage.value = res.data.message
            ? res.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi";
        }
      });
    };
    viewSgatCredenziali();

    const editSgatCredenziali = () => {
      if (!password.value) {
        alertFailed("Per procedere è necessario inserire una password");
      } else if (password.value.length < 8) {
        alertFailed("La password deve contenere un minimo di 8 caratteri");
      } else {
        isLoading.value = true;
        editSgat(id_utente.value, name.value, password.value).then((res) => {
          isLoading.value = false;

          if (res.status == 200) {
            alertSuccess("Credenziali modificate correttamente");
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        });
      }
    };

    return {
      name,
      password,
      editSgatCredenziali,
      errorMessage,
      isLoading,
    };
  },
});
</script>

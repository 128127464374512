import services from "../axios/dbManag";

export const viewSgat = (id_societa) => {
  return services.dbManag
    .post("/sistema/utenti/sgat/societa/view", {
      id_societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editSgat = (id_utente, name, password) => {
  return services.dbManag
    .post("/management/sgat/societa/password/edit", {
      id_utente,
      name,
      password,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
